import NavigationBar from './components/NavigationBar';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/page';
import Download from './pages/download/page';
import Redirect from './pages/registration/Redirect';

export default function App() {
  return (
    <div className="App">
      <div className="content">
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home pageTitle={ "Home" } />}></Route>
          <Route path="/unduh-ebook" element={<Download pageTitle={ "Unduh E-Book" }/>}></Route>
          <Route path="/whatsapp-group" element={<Redirect pageTitle={ "Join Whatsapp Group" }/>}></Route>
        </Routes>
      </div>
    </div>
  );
}

