import { Container } from "react-bootstrap";
import { MessageList, Message, TypingIndicator } from '@chatscope/chat-ui-kit-react';

export default function ChatIllustration() {
    const messages = [
        {
          props: {
            model:{
                message: "Proker yang banyak, effort yang besar, tapi kayak nggak ada hasilnya😔😔", 
                direction: "incoming",
                position: "first"
            },
          }
        },
        {
            props: {
              model:{
                  message: "Kajian yang sepi, konten yang gitu gitu aja, dan acara minim dana", 
                  direction: "incoming",
                  position: "normal"
              },
            }
        },
        {
            props: {
              model:{
                  message: "Regenerasi yang sulit... padahal udah capek2😭😭😭", 
                  direction: "incoming",
                  position: "normal"
              },
            }
        },
        {
            props: {
              model:{
                  message: "Belum lagi minat mahasiswa yang makin menurun terhadap organisasi", 
                  direction: "incoming",
                  position: "last"
              },
            }
        },
        {
            props: {
              model:{
                  message: "Duh, gawat... kalau ga segera berbenah, dakwah kampus bisa jadi kayak nokia ditinggalkan😱", 
                  direction: "outgoing",
                  position: "single"
              },
            }
        },
        {
            props: {
              model:{
                  message: "Melalui kelas ini, kita akan mulai berubah sama2🔥🔥", 
                  direction: "outgoing",
                  position: "single"
              },
            }
        },
      ];
      
    return (
        <Container className="chat-illust" fluid>
            <MessageList
                    typingIndicator={<TypingIndicator content="Pejuang Dakwah Kampus sedang mengetik..." />}>
                {
                    messages.map((m, i) => {
                        let animationClassName = `animate__animated animate__fadeIn animate__delay-${i}s`;
                        return <Message key={i} {...m.props} className={animationClassName} />;
                    })  
                }
            </MessageList>
        </Container>
    );
}