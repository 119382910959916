import { Container } from "react-bootstrap";
import RegistrationButton from "../../components/RegistrationButton";

export default function Closing() {
    return (
        <Container className="closing" fluid>
            <div className="closing__desc">
                Yuk, ikuti <strong>Kelas Aktivis Dakwah Kampus</strong>
            </div>
            <div className="closing__desc">
                Investasikan 2 harimu, untuk belajar disini
            </div>
            <div className="closing__desc">
                demi dakwah yang lebih menyala abangku!
            </div>
            <div className="closing__btn">
                <RegistrationButton />
            </div>
            <div className="closing__bold">
                Saatnya LDK di-upgrade dan meraih kejayaan dakwah kampus!
            </div>
        </Container>
    );
}