import { Card } from "react-bootstrap";

export default function CardBook({ bookTitle, bookPrice, bookCover }) {
    return (
        <Card className="book__card my-3 mx-2" style={{border: "none"}}>
            <Card.Img variant="top" src={ bookCover } />
            <Card.Body>
                <Card.Title className="book__card-title">{bookTitle}</Card.Title>
                <Card.Text className="book__card-price text-end">
                    { `Rp. ${bookPrice.toLocaleString()}, 00` }
                </Card.Text>
            </Card.Body>
        </Card>
    );
}