import { Container, Row, Col } from "react-bootstrap";
import EBookCover1 from "../../assets/ebook_1_cover.jpeg";
import EBookCover2 from "../../assets/ebook_2_cover.jpeg";
import EBookCover3 from "../../assets/ebook_3_cover.jpeg";
import Book from "./Book";

export default function BookSection() {
    const books = [
        {
            bookCover: EBookCover1,
            downloadLink: "https://drive.google.com/uc?export=download&id=1jtWhp7MSOi-NJIjmp9l5WtXL_OB6QqY2"
        },  
        {
            bookCover: EBookCover2,
            downloadLink: "https://drive.google.com/uc?export=download&id=1K6mSbEbPZH42c2fvODu97oME0Lb2pBj7"
        }, 
        {
            bookCover: EBookCover3,
            downloadLink: "https://drive.google.com/uc?export=download&id=1_vR6ACxkVHOTM0RVabOs8keTp6UVKi22"
        }, 
    ];

    return (
        <Container fluid>
            <Row className="justify-content-center">
                {
                    books.map((book, i) =>
                        <Col xs={12} md={4} lg={2} key={i} className="mb-3">
                            <Book {...book} />
                        </Col>
                    )
                }
            </Row>
        </Container>
    );
}