import { Container, Row, Col } from "react-bootstrap";
import { CardSpeaker } from "./CardSpeaker";

import speaker1 from "../../assets/pembicara_maulana.png";
import speaker2 from "../../assets/pembicara_dio.png";
import speaker3 from "../../assets/pembicara_ahmad.png";

export default function Speaker() {
    const speakers = [
        {
            id: 1,
            date: "Sabtu, 27 April 2024 08:30",
            title: "Nyalakan Dakwah Kampus",
            subTitle: "Berdakwah itu meaningful dan worth it",
            speaker: "Ary Maulana",
            speakerAsset: speaker1,
            speakerDesc: "Penulis buku “Reformasi Dakwah Kampus”",
        },
        {
            id: 3,
            date: "Ahad, 28 April 2024 08:30",
            title: "Memimpin Project Dakwah Produktif",
            subTitle: "Membuat agenda dakwah yang relevan dengan objek dakwah dan zaman",
            speaker: "Ahmad Yanis",
            speakerAsset: speaker3,
            speakerDesc: "Learning Designer | Project Coordinator at CIAS",
        },
        {
            id: 2,
            date: "Sabtu, 28 April 2024 10:00",
            title: "Strategi Memviralkan Syiar Islam",
            subTitle: "Cara bikin acara dan konten yang menarik",
            speaker: "Dio. A Diadon",
            speakerAsset: speaker2,
            speakerDesc: "Marbot Real Masjid",
        },
    ];

    return (
        <Container fluid className="speaker">
            <div className="speaker__title">
                Yuk, belajar bersama para ahli 🧐
            </div>
            <Row className="speaker__row">
                { speakers.map((speaker, i) =>
                    <Col xs={12} md={12} lg={12} key={speaker.id} className="my-4">
                        <CardSpeaker {...speaker} /></Col>   
                ) }
            </Row>
        </Container>
    );
}