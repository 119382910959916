export default function CardBenefit({ icon, desc }) {
    return (
        <div className="benefit__card">
            <div className="benefit__card-illust">
                { icon }
            </div>
            <div className="benefit__card-desc">
                { desc }
            </div>
        </div>
    );
}