import { Container, Row, Col } from "react-bootstrap";
import TestimonyCard from "./TestimonyCard";
import TestimonyImage1 from "../../assets/testimony_fakhri.png";
import TestimonyImage2 from "../../assets/testimony_naufal.png";
import TestimonyImage3 from "../../assets/testimony_fadhil.png";

export default function Testimony() {
    const testimonies = [
        {
            profileName: "Fadhil Abdurrahim",
            profileSub: "Ketua Puskomnas FSLDK Indonesia 2024 - 2026",
            profileImg: TestimonyImage3,
            testimony: "Kelas Akselarasi Aktivisi Lembaga Dakwah Kampus adalah salah satu wadah penting yang rasa rasanya hari ini terbatas. Karena ga semua wadah akselerasi menawarkan kurikulum terbarukan dengan pemateri pemateri yang ahli di bidangnya. Saya rasa ini menawarkan itu semua dan teman teman harus join supaya bisa meng-Upgrade skill secara individu, kelembagaan, sehingga bisa mengakselerasi kebaikan dan percepatan di aktivitas dakwah masa sekarang maupun masa yang akan datang."
        },
        {
            profileName: "Muhammad Fakhri Fadhilah",
            profileSub: "Ketua LDK Insani UNDIP 2022",
            profileImg: TestimonyImage1,
            testimony: "Program organisasimu turunan semua? Itu program apa lungsuran baju kakak? Capek-capek buat program eh ga taunya ga menjawab permasalahan apa-apa? Pas mau buat inovasi eh tapi bingung gimana caranya? Kelas Akselerasi Aktivis LDK akan mengajarkan kamu caranya menjadi agen inovatif untuk program dakwah yang lebih level up. Melalui mentor-mentor yang berkompeten kamu akan dibimbing banyak hal dari refleksi dakwah, memimpin project dakwah sampai strategi dakwah relevan.Yuk bergabung di Kelas Akselerasi Aktivis LDK dan jadilah inovator perubahan!"
        },
        {
            profileName: "Abdusyahid Naufal Fathullah",
            profileSub: "Mahasiswa Berprestasi 2 FH UNS",
            profileImg: TestimonyImage2,
            testimony: "Tantangan perubahan zaman semakin kompleks sehingga kita membutuhkan medium atau wadah belajar untuk menghadapi tantangan tersebut dengan baik ,terlebih untuk para Aktivis Lembaga Dakwah Kampus. Kelas Akserlerasi Aktivis LDK merupakan sarana pembelajaran yang progresif untuk meningkatkan kapasitas dan kapabilitas Aktivis LDK. Mari manfaatkan kesempatan  terbatas ini untuk menjadi bagian dari perbaikan negeri ini."
        },
    ];

    return (
        <Container fluid>
            <div className="testimony__title">
                Apa kata mereka 🤔
            </div>  
            <Row className="justify-content-center mx-1">
                {
                    testimonies.map((te, i) => 
                        <Col xs={12} lg={4} key={i} className="my-2">
                            <TestimonyCard {...te} />
                        </Col>    
                    )
                }
            </Row>
        </Container>
      );
}