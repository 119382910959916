import {Row, Col} from "react-bootstrap";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
    return (
        <div className="footer">
           <Row>
            <Col xs={12} md={12} lg={9}>
                <div className="footer__title">
                    ACTIVIST CLASS
                </div>
                <div className="footer__web">
                    www.activistclass.com
                </div>
            </Col>
            <Col xs={12} md={12} lg={3}>
                <div className="footer__socmed" 
                    onClick={() => window.open("https://www.instagram.com/activistclass.idn", "_blank")}>
                    <FontAwesomeIcon icon={faInstagram} /> activistclass.idn 
                </div>
                <div className="footer__socmed"
                    onClick={() => window.open("https://www.instagram.com/fsldkindonesia", "_blank")}>
                    <FontAwesomeIcon icon={faInstagram} /> fsldkindonesia
                </div>
                <div className="footer__socmed"
                    onClick={() => window.open("https://wa.me/+6285755972375", "_blank")}>
                    <FontAwesomeIcon icon={faWhatsapp} /> +6285755972375
                </div>
            </Col>
           </Row>
        </div>
    );
}