import { Row, Col, Container } from "react-bootstrap";
import CardBenefit from "./CardBenefit";

import { faClipboard, faFilm, faPersonRays, faRocket, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Benefit() {
    const benefits = [
        {
            icon: <FontAwesomeIcon icon={faVideo} style={{color: "#518ef7"}} />,
            desc: "Live class via zoom",
        },
        {
            icon: <FontAwesomeIcon icon={faFilm} style={{color: "#1e3050"}} />,
            desc: "Recording lifetime access",
        },
        {
            icon: <FontAwesomeIcon icon={faClipboard} style={{color: "#ffd43b"}} />,
            desc: "Notulensi Acara",
        },
        {
            icon: <FontAwesomeIcon icon={faPersonRays} style={{color: "#0899ff"}} />,
            desc: "Relasi dengan LDK se Indonesia",
        },
        {
            icon: <FontAwesomeIcon icon={faRocket} style={{color: "#ff8408"}} />,
            desc: "Praktek langsung",
        },
    ];

    return (
        <Container fluid className="benefit">
            <div className="benefit__title">
                Kamu bakal dapat ini 🎁
            </div>
            <Row className="justify-content-center">
                {
                    benefits.map((benefit, i) => 
                        <Col xs={6} md={6} lg={2} className="my-2" key={i}>
                            <CardBenefit {...benefit} />
                        </Col>)
                }
            </Row>
        </Container>
    );
}