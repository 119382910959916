import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";

export default function Book({ bookCover, downloadLink }) {
    const getDownload = (downloadLink) => {
        window.location.replace(downloadLink);
    };

    return (
        <Card className="book__card book__modal" style={{border: "none"}}>
            <Card.Img variant="top" src={ bookCover } />
            <Card.Body>
                <Card.Text className="book__card-price text-center">
                    <Button className="btn__download" onClick={e => getDownload(downloadLink) }>
                        <FontAwesomeIcon icon={faDownload} /> Unduh
                    </Button>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}