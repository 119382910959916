import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ActivistLogo from "../assets/activist_class_logo_nav.png";
import FSLDKLogo from "../assets/fsldk_indonesia_logo_nav.png";

function NavigationBar() {
  const activeNav = ({ isActive }) => {
    return isActive ? "navigation-bar__item-active" : "navigation-bar__item";
  };

  return (
    <Navbar className="navigation-bar fixed-top" expand="lg">
      <Container fluid>
        <Navbar.Brand className="navigation-bar__brand">
          <Image src={ActivistLogo} />
          <Image src={FSLDKLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav activeKey="/">
            <Nav.Item>
              <NavLink className={ activeNav } to="/">Home</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className={ activeNav } to="/unduh-ebook">Unduh E-Book</NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;