import { Container } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import RegistrationButton from "../../components/RegistrationButton";

export default function Banner() {
    return (
        <Container fluid className="banner">
            <Container className="banner-fixed">
                <TypeAnimation className="banner__title"
                    sequence={['KELAS AKSELERASI AKTIVIS LEMBAGA DAKWAH KAMPUS', 800, '']}
                    repeat={Infinity} />
            </Container>
            <Container>
                <h6 className="banner__sub">SAATNYA MENGUPGRADE LDK 🚀</h6>
                <RegistrationButton />
            </Container>
        </Container>
    );
}