import { Container, Row, Col } from "react-bootstrap";
import CardBook from "./CardBook";
import EBookCover1 from "../../assets/ebook_1_cover.jpeg";
import EBookCover2 from "../../assets/ebook_2_cover.jpeg";
import EBookCover3 from "../../assets/ebook_3_cover.jpeg";
import EBookButton from "./EBookButton";

export default function Book() {
    const books = [
        {
            bookTitle: "UDAH LOGIN LDK?",
            bookPrice: 75000,
            bookCover: EBookCover1,
        },  
        {
            bookTitle: "Organisasi Anti Drama",
            bookPrice: 75000,
            bookCover: EBookCover2,
        }, 
        {
            bookTitle: "SYIAR PRODUKTIF",
            bookPrice: 75000,
            bookCover: EBookCover3,
        }, 
    ];

    return (
        <Container fluid>
            <div className="book__title">
                Nggak cuma itu… bakal ada bonus super menarik buat kamu 🤫
            </div>
            <Row className="justify-content-center">
                {
                    books.map((book, i) =>
                        <Col xs={12} md={4} lg={2} key={i}>
                            <CardBook {...book} />
                        </Col>
                    )
                }
            </Row>
            <div className="book__desc">
                Exclusive 3 Series E-Book “Saatnya LDK di-Upgrade”
            </div>
            <div className="book__desc__price">
                <EBookButton />
            </div>
        </Container>
    );
}