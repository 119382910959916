import { Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

export default function RegistrationButton() {
    // const navigate = useNavigate();

    // return (<Button className="registration__button" onClick={() => navigate(`/daftar`)}>
    //     Daftar disini!</Button>);

    return (<Button className="registration__button" disabled>
        Pendaftaran ditutup</Button>);
}