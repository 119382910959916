import { Image, Row, Col } from "react-bootstrap";

export function CardSpeaker({ date, title, subTitle, speaker, speakerAsset, speakerDesc }) {
    return (
        <Row className="speaker__card">
            <Col xs={12} md={12} lg={3} className="speaker__avatar">
                <Image src={ speakerAsset } roundedCircle />
            </Col>
            <Col xs={12} md={12} lg={9}>
                <div className="speaker__card__title mb-3"> 
                    { title }
                </div>
                <div className="speaker__card__title-sub mb-3"> 
                    { subTitle }
                </div>
                <div className="speaker__card__date mb-3"> 
                    { date }
                </div>
                <div className="speaker__card__speaker-name"> 
                    { speaker }
                </div>
                <div className="speaker__card__speaker-desc"> 
                    { speakerDesc }
                </div>
            </Col>
            {/* <div className="speaker__card__title">
                { title }
            </div>
            <div className="speaker__card__sub-title">
                { subTitle }
            </div>
            <div className="speaker__card__presenter">
                <Image src={ speakerAsset } roundedCircle />
                <div className="speaker__card__presenter-name">
                    <div className="speaker__card__presenter-name-highlight">
                        { speaker }
                    </div>
                    { speakerDesc }
                </div>
            </div>
            <div className="speaker__card__date">
                <FontAwesomeIcon icon={ faCalendarCheck } /> 
                <span className="speaker__card__date-highlight">{ dateHighlight }</span> { date } pukul { hour }
            </div>                  */}
        </Row>
    );
}