import { Container, Accordion } from "react-bootstrap";

export default function Faq() {
    return (
        <Container fluid className="faq">
            <div className="faq__title mb-4">
                Masih penasaran sama Activist Class Indonesia ?
            </div>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="faq-item">
                    <Accordion.Header>Apa sih Activist Class Indonesia ?</Accordion.Header>
                    <Accordion.Body className="faq-item__answer">
                        Activist Class Indonesia adalah project kolaborasi bersama FSLDK Indonesia. Project ini berupa kelas yang aksesibel dan di isi oleh para “suhu” seputar ke LDK-an. 
                        Kelas ini istimewa untuk aktivis lembaga dakwah kampus. Setiap amal harus diawali dengan Ilmu, dan Activist Class Indonesia membantu memfasilitasi itu😁.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="faq-item">
                    <Accordion.Header>Kelas ini untuk siapa ?</Accordion.Header>
                    <Accordion.Body className="faq-item__answer">
                        Untuk siapapun yang mau belajar, khususnya Aktivis Lembaga Dakwah Kampus😊!
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
}