import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

const Clock = ({ deadline }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);

    return () => getTimeUntil(deadline);
  }, [deadline]);

  return (
    <>
        <div className="clock__title">
        🎉 Belajar mengembangkan lembaga dakwah yang relevan dengan zaman now! 🎉
        </div>
        <div className="clock__title-sub">
           Batas waktu pendaftaran
        </div>
        <Row className="clock">
            <Col className="clock__item">
                <div className="clock__item-number">
                    { leading0(days) }
                </div>
                <div className="clock__item-caps">
                    Hari
                </div>
            </Col>
            <Col className="clock__item">
                <div className="clock__item-number">
                    { leading0(hours) }
                </div>
                <div className="clock__item-caps">
                    Jam
                </div>
            </Col>
            <Col className="clock__item">
                <div className="clock__item-number">
                    { leading0(minutes) }
                </div>
                <div className="clock__item-caps">
                    Menit
                </div>
            </Col>
            <Col className="clock__item">
                <div className="clock__item-number">
                    { leading0(seconds) }
                </div>
                <div className="clock__item-caps">
                    Detik
                </div>
            </Col>
        </Row>
    </>
  );
};

export default Clock;
