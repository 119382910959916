import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container } from "react-bootstrap";

export default function Redirect() {
    return (
        <Container className="redirect">
            <div className="redirect__title">
            🎊 Berhasil mendaftar 🎊 
            </div>
            <div className="redirect__btn-area">
                <Button className="redirect__whatsapp-group"
                    onClick={(e) => window.location
                        .replace("https://chat.whatsapp.com/BMCeOhQefPy3UDozA5zAa6", "_blank")}>
                    <FontAwesomeIcon icon={faWhatsapp} /> Masuk group Whatsapp 1
                    <span className="bg-danger px-2 mx-3">penuh</span>
                </Button>
                <Button className="redirect__whatsapp-group"
                    onClick={(e) => window.location
                        .replace("https://chat.whatsapp.com/HFF6zIDKz8zIGVB8Pqx0Ti", "_blank")}>
                    <FontAwesomeIcon icon={faWhatsapp} /> Masuk group Whatsapp 2
                </Button>
            </div>
        </Container>    
    );
}