import { Container, Row, Col } from "react-bootstrap";
import Banner from "./Banner";
import poster from "../../assets/poster.png";
import Speaker from "./Speaker";
import Benefit from "./Benefit";
import ChatIllustration from "./ChatIllustration";
import Testimony from "./Testimony";
import Clock from "./Clock";
import Book from "./Book";
import Faq from "./Faq";
import Footer from "../../components/Footer";
import Closing from "./Closing";

export default function Home() {
    return (
        <>
            <Container fluid className="home">
                <Row className="justify-content-center">
                    <Col xs={12} md={12} lg={12} className="home__banner">
                        <Banner />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__poster">
                        <img src={poster} className="" alt="Poster" />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__clock">
                        <Clock deadline={"2024-04-26T23:00:00"} />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__chat">
                        <ChatIllustration />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__benefit">
                        <Benefit />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__book">
                        <Book />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__speaker">
                        <Speaker />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__testimony">
                        <Testimony />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__faq">
                        <Faq />
                    </Col>
                    <Col xs={12} md={12} lg={12} className="home__closing">
                        <Closing />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}