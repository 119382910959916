import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Image } from "react-bootstrap";

export default function TestimonyCard({ profileName, profileSub, profileImg, testimony }) {
    return (
        <div className="testimony-card">
            <FontAwesomeIcon icon={faQuoteRight} className="testimony-card__icon"/>
            <div className="testimony-card__content">
                { testimony }   
            </div>     
            <Row className="testimony-card__profile">
                <Col xs={3} md={2} lg={2}>
                    <Image src={profileImg} rounded/>
                </Col>
                <Col className="testimony-card__profile-desc">
                    <div className="testimony-card__from__name">{ profileName }</div>
                    <div className="testimony-card__from__sub">{ profileSub }</div>
                </Col>
            </Row>
        </div>
      );
}